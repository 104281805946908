define("ember-svg-jar/inlined/view_large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.75 4a2.75 2.75 0 012.745 2.582l.005.168v1a2.75 2.75 0 01-2.582 2.745l-.168.005h-11a2.75 2.75 0 01-2.745-2.582L4 7.75v-1a2.75 2.75 0 012.582-2.745L6.75 4h11zm0 1.5h-11a1.25 1.25 0 00-1.244 1.122L5.5 6.75v1c0 .647.492 1.18 1.122 1.244L6.75 9h11a1.25 1.25 0 001.244-1.122L19 7.75v-1a1.25 1.25 0 00-1.122-1.244L17.75 5.5zm0 6.5a2.75 2.75 0 012.745 2.582l.005.168v1a2.75 2.75 0 01-2.582 2.745l-.168.005h-11a2.75 2.75 0 01-2.745-2.582L4 15.75v-1a2.75 2.75 0 012.582-2.745L6.75 12h11zm0 1.5h-11a1.25 1.25 0 00-1.244 1.122l-.006.128v1c0 .647.492 1.18 1.122 1.244L6.75 17h11a1.25 1.25 0 001.244-1.122L19 15.75v-1a1.25 1.25 0 00-1.122-1.243l-.128-.007z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});