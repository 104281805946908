define("ember-svg-jar/inlined/tick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10 17a.648.648 0 01-.5-.214L5.214 12.5a.69.69 0 010-1 .69.69 0 011 0L10 15.286l8.071-8.072a.69.69 0 011 0 .69.69 0 010 1L10.5 16.786A.648.648 0 0110 17z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});