define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.396 10.676l-1.391-2.855-1.41 2.856-3.135.469 2.28 2.223-.539 3.168 2.813-1.48 2.795 1.48-.522-3.168 2.261-2.221-3.152-.472zM11.288 6.35zm1.168-.57l1.809 3.712 4.06.607a.504.504 0 01.275.855l-2.92 2.87.673 4.092a.497.497 0 01-.723.526l-3.618-1.914-3.643 1.915a.501.501 0 01-.722-.53l.697-4.09-2.942-2.869a.502.502 0 01.273-.856l4.055-.606 1.833-3.714a.497.497 0 01.893.002z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});