define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.742 13l4.516.005a4.745 4.745 0 014.737 4.533l.005.217v1a.75.75 0 01-1.49.102l-.007-.102v-1a3.248 3.248 0 00-3.06-3.245l-.185-.005-4.516-.005a3.247 3.247 0 00-3.24 3.066l-.005.184v1a.75.75 0 01-1.49.102L5 18.75v-1a4.746 4.746 0 014.525-4.745L9.742 13zM12 4a4 4 0 110 8 4 4 0 010-8zm0 1.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});