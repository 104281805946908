define("ember-svg-jar/inlined/user_solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.742 13l4.516.005a4.745 4.745 0 014.737 4.533l.005.217v1a.75.75 0 01-.749.75c-1.453 0-7.185-.005-12.502-.005a.75.75 0 01-.742-.648L5 18.75v-1a4.746 4.746 0 014.525-4.745L9.742 13zM12 4a4 4 0 110 8 4 4 0 010-8z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});