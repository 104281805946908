define("ember-svg-jar/inlined/file_html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 4.25a2.75 2.75 0 012.745 2.582L18.75 7v10a2.75 2.75 0 01-2.582 2.745L16 19.75H9a2.75 2.75 0 01-2.745-2.582L6.25 17V7a2.75 2.75 0 012.582-2.745L9 4.25h7zm0 1.5H9a1.25 1.25 0 00-1.244 1.122L7.75 7v10c0 .647.492 1.18 1.122 1.244L9 18.25h7a1.25 1.25 0 001.244-1.122L17.25 17V7a1.25 1.25 0 00-1.122-1.244L16 5.75zm-1.417 8.5a.75.75 0 01.102 1.493l-.102.007h-4.166a.75.75 0 01-.102-1.493l.102-.007h4.166zm0-3a.75.75 0 01.102 1.493l-.102.007h-4.166a.75.75 0 01-.102-1.493l.102-.007h4.166zm-2.083-3a.75.75 0 01.102 1.493l-.102.007h-2.083a.75.75 0 01-.102-1.493l.102-.007H12.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});