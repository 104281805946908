define("ember-svg-jar/inlined/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.893 7.786a.893.893 0 100-1.786.893.893 0 000 1.786zm15.026-.112H7.57a.781.781 0 110-1.562H19.92a.781.781 0 110 1.562zm0 5.357H7.57a.781.781 0 110-1.562H19.92a.781.781 0 010 1.562zM7.57 18.388H19.92a.781.781 0 000-1.562H7.57a.781.781 0 100 1.562zM5.786 12.25a.893.893 0 11-1.786 0 .893.893 0 011.786 0zm-.893 6.25a.893.893 0 100-1.786.893.893 0 000 1.786z\" fill=\"currentcolor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});