define("ember-svg-jar/inlined/flag_eu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.5 12a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM6.77 8.48V15h4.18v-1.24H8.24v-1.5h2.22v-1.23H8.24V9.72h2.61V8.48H6.77zm6.11 5.89c.447.5 1.093.75 1.94.75.84 0 1.478-.25 1.915-.75.437-.5.655-1.297.655-2.39v-3.5h-1.42v3.65c0 .64-.1 1.087-.3 1.34-.2.253-.483.38-.85.38s-.647-.127-.84-.38c-.193-.253-.29-.7-.29-1.34V8.48h-1.48v3.5c0 1.093.223 1.89.67 2.39z\" fill=\"#306AB2\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});