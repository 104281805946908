define("ember-svg-jar/inlined/box_add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.94 3.59a.64.64 0 01.466-.19.64.64 0 01.466.19.617.617 0 01.192.457v1.94h1.977a.64.64 0 01.466.19.618.618 0 01.193.457.618.618 0 01-.193.457.64.64 0 01-.466.19h-1.977v1.94a.617.617 0 01-.192.457.64.64 0 01-.466.19.64.64 0 01-.466-.19.617.617 0 01-.193-.457v-1.94H16.77a.64.64 0 01-.466-.19.617.617 0 01-.193-.457c0-.179.065-.331.193-.457a.64.64 0 01.466-.19h1.977v-1.94c0-.179.064-.331.193-.458zm-.252 9.022a5.968 5.968 0 001.336.02v3.363a2.003 2.003 0 01-2.003 2.003H6.034C4.928 17.998 4 17.1 4 15.995v-6.01L8.006 5.98h5.558a5.96 5.96 0 00-.021 1.335H8.559l-2.67 2.67H9.34c0 1.336 1.196 2.671 2.67 2.671 1.38 0 2.514-1.166 2.656-2.41.308.419.67.794 1.075 1.118-.59 1.501-2.095 2.628-3.75 2.628-1.672 0-3.202-1.148-3.779-2.67H5.335v4.673c0 .362.32.667.7.667H18.02c.368 0 .667-.3.667-.667v-3.383z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});