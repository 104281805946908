define("ember-svg-jar/inlined/comment_solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.398 3.429c-1.482 0-2.684 1.27-2.684 2.838v10.405c0 1.567 1.202 2.838 2.684 2.838h.895v4.127a.3.3 0 00.48.24l5.783-4.367h7.157c1.482 0 2.684-1.27 2.684-2.838V6.267c0-1.568-1.202-2.838-2.684-2.838H4.398z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});