define("ember-svg-jar/inlined/star_of_david", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 20.11l-4.578 2.176-1.196-5.027-4.512-2.315L4.8 10.85 3.75 5.79l5.044-.078L12 1.714l3.204 3.997 5.044.078L19.2 10.85l3.085 4.094-4.512 2.315-1.196 5.027L12 20.11z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});