define("ember-svg-jar/inlined/comment_plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.886 17.09H3.818a1.024 1.024 0 01-1.023-1.022V4.818c0-.564.46-1.023 1.023-1.023h16.364c.564 0 1.023.46 1.023 1.023v11.25c0 .564-.46 1.023-1.023 1.023h-8.837l-4.459 3.184v-3.184zm-1.5 1.5H3.818a2.523 2.523 0 01-2.523-2.522V4.818a2.524 2.524 0 012.523-2.523h16.364a2.524 2.524 0 012.523 2.523v11.25a2.524 2.524 0 01-2.523 2.523h-8.357L5.386 23.19v-4.6z\" fill=\"currentcolor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.813 6.75a.75.75 0 00-.75.75v2.062H9a.75.75 0 00-.75.75v.376c0 .414.336.75.75.75h2.063V13.5c0 .414.335.75.75.75h.374a.75.75 0 00.75-.75v-2.063H15a.75.75 0 00.75-.75v-.374a.75.75 0 00-.75-.75h-2.063V7.5a.75.75 0 00-.75-.75h-.374z\" fill=\"currentcolor\"/>",
    "attrs": {
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});