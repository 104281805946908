define("ember-svg-jar/inlined/CROW", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.413 11.408l1.33-3.076H9.436c-3.104 0-4.33 2.79-4.33 5.188v2.086c0 2.477 1.226 5.488 4.33 5.488h8.582v-3.077H9.865c-1.017 0-1.213-1.147-1.213-2.411V13.52c0-1.16.235-2.112 1.2-2.112h6.561z\" fill=\"#373D42\"/><path d=\"M8.782 6.063h9.81L19.921 3H9.826C6.943 3 5.678 5.412 5.52 7.68c.692-.952 1.761-1.617 3.261-1.617z\" fill=\"#009FE3\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});