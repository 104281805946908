define("ember-svg-jar/inlined/flag_nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"3\" y=\"3\" width=\"18\" height=\"18\"><circle cx=\"12\" cy=\"12\" r=\"8.5\" fill=\"#04536E\"/></mask><g mask=\"url(#a)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M0 3.5v17.25h23V3.5H0z\" fill=\"#F7FCFF\"/><path d=\"M0 3.5v17.25h23V3.5H0z\" fill=\"#F7FCFF\"/><path d=\"M0 3.5v5.75h23V3.5H0z\" fill=\"#E31D1C\"/><path d=\"M0 15v5.75h23V15H0z\" fill=\"#3D58DB\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});