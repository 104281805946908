define("ember-svg-jar/inlined/warning_outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.958 10.788l-.255 2.291c-.029.285-.09.5-.188.656-.108.173-.296.265-.545.265-.253 0-.44-.09-.54-.26-.088-.15-.15-.37-.186-.67l-.19-2.23c-.037-.44-.054-.748-.054-.94 0-.277.096-.499.286-.658.192-.16.446-.242.755-.242.382 0 .643.109.776.323.123.197.183.47.183.833 0 .206-.014.419-.042.632zM12.005 17c.263 0 .496-.09.692-.265.2-.18.302-.428.303-.738 0-.275-.1-.513-.296-.707a.98.98 0 00-.71-.29.96.96 0 00-.705.291.97.97 0 00-.29.706c0 .314.104.563.307.741.198.174.434.262.7.262z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.04 15.922L9.534 5.247C10.003 4.335 10.925 3.7 12 3.7c1.075 0 1.997.635 2.466 1.546l5.494 10.677c.458.89.453 1.97-.015 2.858-.473.895-1.387 1.519-2.452 1.519H6.506c-1.063 0-1.978-.623-2.451-1.519a3.115 3.115 0 01-.014-2.859zm14.764.596L13.31 5.84C13.042 5.321 12.542 5 12 5c-.543 0-1.043.32-1.31.841L5.196 16.518a1.812 1.812 0 00.008 1.656c.27.511.766.826 1.302.826h10.987c.537 0 1.032-.315 1.302-.826l.009-1.656z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});