define("ember-svg-jar/inlined/shoppingbasket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19 7.75a1.75 1.75 0 011.744 1.606l.006.144v1a1.75 1.75 0 01-1.056 1.607l-.637 6.562a1.75 1.75 0 01-1.594 1.575l-.148.006H6.685c-.85 0-1.57-.61-1.72-1.433l-.022-.148-.637-6.562c-.577-.25-.993-.8-1.05-1.454L3.25 10.5v-1a1.75 1.75 0 011.606-1.744L5 7.75h14zm-.827 4.5H5.826l.61 6.274a.25.25 0 00.13.196l.057.022.062.008h10.63a.25.25 0 00.235-.165l.014-.06.61-6.275zm-2.682.5l.102.006a.75.75 0 01.657.735l-.006.102-.5 4a.75.75 0 01-1.494-.084l.006-.102.5-4a.75.75 0 01.735-.657zm-3.491 0a.75.75 0 01.743.648l.007.102v4a.75.75 0 01-1.493.102l-.007-.102v-4a.75.75 0 01.75-.75zm-2.77.558l.018.1.496 4a.75.75 0 01-1.469.284l-.02-.1-.495-4a.75.75 0 011.47-.284zM19 9.25H5a.25.25 0 00-.243.193L4.75 9.5v1a.25.25 0 00.193.243L5 10.75h14a.25.25 0 00.243-.193l.007-.057v-1a.25.25 0 00-.193-.243L19 9.25zm-3-5l.143.006a1.75 1.75 0 011.6 1.593L17.75 6v.5l-.007.102a.75.75 0 01-1.486 0L16.25 6.5V6l-.007-.057a.25.25 0 00-.175-.184L16 5.75H8l-.057.007a.25.25 0 00-.184.175L7.75 6v.5l-.007.102a.75.75 0 01-1.486 0L6.25 6.5V6l.006-.144a1.75 1.75 0 011.593-1.6L8 4.25h8z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});