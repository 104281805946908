define("ember-svg-jar/inlined/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.773 5.25H6.227a1.75 1.75 0 00-1.163.442l-.114.111a1.75 1.75 0 00-.031 2.36l4.83 5.434v3.623a1.725 1.725 0 002.654 1.454l1.039-.663.132-.094a1.75 1.75 0 00.676-1.381l-.001-2.939 4.832-5.434a1.75 1.75 0 00-1.308-2.913zM6.227 6.75h11.546a.25.25 0 01.187.416l-5.021 5.648a.75.75 0 00-.19.498v3.224a.25.25 0 01-.115.21l-1.038.664a.225.225 0 01-.346-.19v-3.907a.75.75 0 00-.19-.498L6.04 7.166a.25.25 0 01.187-.416z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});