define("ember-svg-jar/inlined/arrow_switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.695 5.178l.076.066 12.213 12.192.027-3.21a.744.744 0 01.638-.732l.11-.007a.745.745 0 01.733.637l.008.11-.035 4.991a.744.744 0 01-.632.731l-.11.009-5 .035a.744.744 0 01-.117-1.478l.11-.009 3.214-.027L4.718 6.296a.742.742 0 010-1.052.746.746 0 01.977-.066zm3.86 7.698l1.053 1.05-4.837 4.83a.745.745 0 01-1.12-.975l.067-.076 4.837-4.829zM13.724 4l5 .035a.744.744 0 01.732.63l.009.11.035 4.991a.744.744 0 01-1.48.117l-.01-.11-.026-3.208-4.567 4.559-1.054-1.052 4.568-4.559-3.215-.026a.744.744 0 01-.733-.637l-.008-.11a.744.744 0 01.639-.732l.11-.008z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});