define("ember-svg-jar/inlined/search_document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.145 4.364c-2.955 0-5.36 2.377-5.36 5.322 0 2.945 2.405 5.322 5.36 5.322 1.05 0 2.033-.3 2.861-.82l4.34 4.303c.282.28.738.28 1.02 0a.712.712 0 000-1.013l-4.251-4.216a5.28 5.28 0 001.39-3.576c0-2.945-2.405-5.322-5.36-5.322zm-3.923 5.322c0-2.14 1.75-3.885 3.923-3.885 2.172 0 3.923 1.745 3.923 3.885 0 2.14-1.751 3.885-3.923 3.885-2.173 0-3.923-1.745-3.923-3.885z\" fill=\"currentcolor\"/><path d=\"M1.693 7.072h4.01a.932.932 0 00.936-.929.932.932 0 00-.936-.928h-4.01a.932.932 0 00-.936.928c0 .513.42.929.936.929zM1.693 13.436h4.01a.932.932 0 00.936-.928.932.932 0 00-.936-.928h-4.01a.932.932 0 00-.936.928c0 .512.42.928.936.928zM16.484 19.8H1.693a.932.932 0 01-.936-.928c0-.512.42-.928.936-.928h14.791c.517 0 .936.416.936.928a.932.932 0 01-.936.928z\" fill=\"currentcolor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});