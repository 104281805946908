define("ember-svg-jar/inlined/arrow_down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.575 12.474l-.073.084-5 4.95a.75.75 0 01-.971.072l-.084-.072-5-4.95a.75.75 0 01.97-1.138l.085.072 3.723 3.684v-9.15a.75.75 0 011.493-.103l.007.102v9.152l3.722-3.685a.75.75 0 01.977-.068l.084.074a.75.75 0 01.067.976z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});