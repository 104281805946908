define("ember-svg-jar/inlined/arrow_left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.5 6.4l-.083.072-4.95 5a.75.75 0 00-.072.972l.072.084 4.95 5a.75.75 0 001.138-.971l-.072-.085-3.684-3.722h9.15a.75.75 0 00.102-1.493l-.101-.007H8.798l3.685-3.722a.75.75 0 00.067-.977l-.073-.084a.75.75 0 00-.976-.068z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});