define("ember-svg-jar/inlined/email_notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.781 20.313H5.156c-.776 0-1.406-.63-1.406-1.407V7.656c0-.776.63-1.406 1.406-1.406h16.875c.777 0 1.407.63 1.407 1.406v6.328\" stroke=\"currentcolor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M23.12 6.766l-7.635 5.872a3.101 3.101 0 01-3.782 0L4.068 6.766\" stroke=\"currentcolor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path clip-rule=\"evenodd\" d=\"M20.752 16.568a3.516 3.516 0 012.101 4.506l-.962 2.643a1.406 1.406 0 00.84 1.802l-9.25-3.366a1.406 1.406 0 001.803-.841l.962-2.643a3.516 3.516 0 014.506-2.101v0z\" stroke=\"currentcolor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M18.635 27.237a1.75 1.75 0 01-2.973-1.082l2.973 1.082z\" stroke=\"currentcolor\" stroke-width=\"1.5\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});