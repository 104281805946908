define("ember-svg-jar/inlined/cross_solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 21a9 9 0 110-18 9 9 0 010 18zm0-10.472L9.827 8.355a.991.991 0 00-.73-.299c-.286.002-.53.104-.734.307a1.008 1.008 0 00-.307.734.985.985 0 00.3.73L10.528 12l-2.174 2.174c-.2.2-.3.444-.299.73.002.285.104.53.307.732.204.204.448.306.734.308a.985.985 0 00.73-.299L12 13.472l2.174 2.173c.2.2.444.3.73.299.285-.002.53-.104.733-.308.203-.202.305-.447.307-.733a.985.985 0 00-.299-.73L13.472 12l2.173-2.174c.2-.2.3-.444.299-.73a1.008 1.008 0 00-.307-.733 1.008 1.008 0 00-.734-.307.986.986 0 00-.73.299L12 10.528z\" fill=\"currentcolor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});