define("ember-svg-jar/inlined/star_solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.265 9.492L12.456 5.78a.497.497 0 00-.893-.003L9.73 9.492l-4.055.606a.501.501 0 00-.273.856l2.942 2.87-.696 4.09a.501.501 0 00.721.529l3.643-1.915 3.618 1.914a.497.497 0 00.723-.526l-.673-4.092 2.92-2.87a.504.504 0 00-.275-.855l-4.06-.607z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});