define("ember-svg-jar/inlined/cross_close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.42 4.58a.75.75 0 01.067.984l-.067.077L13.06 12l6.359 6.359a.75.75 0 01-.984 1.128l-.077-.067L12 13.06l-6.358 6.36a.75.75 0 01-1.128-.984l.067-.077L10.94 12 4.58 5.641a.75.75 0 01.984-1.127l.077.067L12 10.939l6.36-6.358a.75.75 0 011.06 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});