define("ember-svg-jar/inlined/file_lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.05 4h-9.1C6.873 4 6 4.86 6 5.92v11.523c0 1.061.873 1.92 1.95 1.92h9.1c1.077 0 1.95-.859 1.95-1.92V5.92C19 4.86 18.127 4 17.05 4zm.768 13.532c0 .359-.297.65-.665.65H7.846a.657.657 0 01-.664-.65v-11.7c0-.36.297-.65.664-.65h9.307c.368 0 .665.29.665.65v11.7zm-2.826-2.896H10.15a.598.598 0 00-.605.591c0 .327.271.591.605.591h4.842a.598.598 0 00.605-.59.598.598 0 00-.605-.592zm0-2.363H10.15a.598.598 0 00-.605.59c0 .327.271.591.605.591h4.842a.598.598 0 00.605-.59.598.598 0 00-.605-.591zm0-2.364H10.15a.598.598 0 00-.605.591c0 .326.271.591.605.591h4.842a.598.598 0 00.605-.591.598.598 0 00-.605-.59zm-4.943-1.182h2.018c.278 0 .504-.264.504-.59 0-.327-.226-.592-.504-.592h-2.018c-.278 0-.504.265-.504.591 0 .327.226.591.505.591z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});