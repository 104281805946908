define("ember-svg-jar/inlined/arrow_up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.374 11.526l.073-.084 5-4.95a.75.75 0 01.971-.072l.084.072 5 4.95a.75.75 0 01-.97 1.138l-.085-.072-3.722-3.684v9.15a.75.75 0 01-1.493.103l-.007-.102V8.823l-3.723 3.685a.75.75 0 01-.977.068l-.083-.074a.75.75 0 01-.068-.976z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});