define("ember-svg-jar/inlined/box_alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.58 11.318a4.31 4.31 0 100-8.618 4.31 4.31 0 000 8.618zm.477-2.327a.742.742 0 01-.487.173.762.762 0 01-.492-.17.59.59 0 01-.216-.483c0-.18.069-.335.204-.46a.704.704 0 01.496-.19c.194 0 .362.064.5.19a.604.604 0 01.208.46.595.595 0 01-.213.48zm.004-2.198l.179-1.307c.02-.121.03-.242.03-.36 0-.207-.043-.363-.129-.475-.094-.122-.277-.184-.546-.184-.217 0-.397.046-.531.138a.432.432 0 00-.202.375c0 .11.013.285.038.536l.134 1.272a.874.874 0 00.131.382c.07.097.202.148.38.148.175 0 .307-.052.383-.15a.753.753 0 00.133-.375z\" fill=\"currentColor\"/><path d=\"M18.649 12.629a5.954 5.954 0 001.331.02v3.353A1.997 1.997 0 0117.983 18H6.029C4.925 18 4 17.106 4 16.002V10.01l3.995-3.995h5.543a5.947 5.947 0 00-.021 1.331h-4.97L5.882 10.01h3.444c0 1.332 1.192 2.663 2.663 2.663 1.375 0 2.506-1.164 2.648-2.403.307.417.668.791 1.072 1.114-.587 1.497-2.09 2.62-3.74 2.62-1.667 0-3.193-1.144-3.768-2.662h-2.87v4.66c0 .361.319.666.697.666h11.954a.667.667 0 00.666-.666V12.63z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});