define("ember-svg-jar/inlined/share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.75 5a.75.75 0 01.102 1.493L9.75 6.5h-1a2.25 2.25 0 00-2.245 2.096L6.5 8.75v6a2.25 2.25 0 002.096 2.245L8.75 17h6a2.25 2.25 0 002.245-2.096L17 14.75v-1a.75.75 0 011.493-.102l.007.102v1a3.75 3.75 0 01-3.55 3.745l-.2.005h-6a3.75 3.75 0 01-3.745-3.55L5 14.75v-6a3.75 3.75 0 013.55-3.745L8.75 5h1zm7.75 0a1 1 0 011 1v4.908a.77.77 0 01-1.534.104l-.007-.104-.002-3.277-4.642 4.643a.77.77 0 01-1.164-1.003l.075-.087 4.642-4.643h-3.276a.77.77 0 01-.764-.666l-.006-.104c0-.39.29-.713.666-.764L12.591 5H17.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});