define("ember-svg-jar/inlined/box_edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 5H7L3 9v6c0 1.133.933 2 2 2h3.524a.666.666 0 100-1.333H5A.668.668 0 014.333 15v-4.667H7.2C7.8 11.867 9.333 13 11 13c1.546 0 2.977-.975 3.602-2.34l.061-.142c.025-.061.048-.123.07-.185h.005c.065-.163.129-.342.188-.53.13-.41-.195-.803-.626-.803-.382 0-.684.315-.79.681-.313 1.072-1.322 1.986-2.51 1.986-1.435 0-2.609-1.334-2.609-2.667H5l2.609-2.667h6.782l2.013 2.058a.702.702 0 101-.988L15 5z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.682 10.707l.61.611a1 1 0 010 1.414l-7.12 7.122a.5.5 0 01-.354.146H12.5a.5.5 0 01-.5-.5v-1.318a.5.5 0 01.146-.354l7.122-7.12a1 1 0 011.414 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});