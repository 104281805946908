define("ember-svg-jar/inlined/circle_tick_solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 4a8 8 0 110 16 8 8 0 010-16zm4.184 4.705a.89.89 0 00-1.166-.08l-.091.08-4.193 4.19-1.661-1.66-.123-.103A.89.89 0 007.736 12.4l.08.09 2.29 2.291.124.103a.89.89 0 001.036-.018l.098-.085 4.82-4.82.103-.123a.89.89 0 00-.103-1.134z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});