define("ember-svg-jar/inlined/tag_solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.511 4.25c.458 0 .898.182 1.222.506l6.51 6.511a1.728 1.728 0 010 2.444l-5.532 5.533a1.728 1.728 0 01-2.444 0l-6.51-6.511a1.728 1.728 0 01-.507-1.222V5.978c0-.954.774-1.728 1.728-1.728h5.533zM8.5 7a1.5 1.5 0 100 3 1.5 1.5 0 000-3z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});