define("ember-svg-jar/inlined/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.652 4.001h-5.88C5.795 4.001 5 4.795 5 5.774V17.59c0 .979.794 1.772 1.773 1.772h9.453c.98 0 1.773-.793 1.773-1.772V9.36a.578.578 0 00-.173-.459L13.1 4.174a.578.578 0 00-.448-.173zm-.561 1.182v4.136c0 .326.264.59.59.59h4.136v7.682a.59.59 0 01-.59.59H6.772a.59.59 0 01-.591-.59V5.774a.59.59 0 01.59-.591h5.319zm1.182.836v2.71h2.709l-2.71-2.71z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});