define("ember-svg-jar/inlined/horn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.83 5.243a1.373 1.373 0 00-1.218-.17L5.221 8.427a3.095 3.095 0 000 5.938l2.19.646v1.797A4.191 4.191 0 0011.604 21h1.765a.662.662 0 000-1.323h-1.765a2.868 2.868 0 01-2.868-2.868v-1.407l7.877 2.32c.426.14.852.072 1.218-.17.388-.257.611-.652.611-1.126v-2.381a2.868 2.868 0 000-5.296V6.385c0-.473-.213-.879-.611-1.142zm.611 5.073v2.162a1.54 1.54 0 00.441-1.08c0-.422-.168-.803-.44-1.082zm-1.43 6.144l-8.276-2.438v-5.25l8.277-2.438.013-.004a.11.11 0 01.024-.006h.003s.004 0 .011.003a.21.21 0 01.053.032l.002.026v10.05a.208.208 0 01-.054.033l-.008.002h-.007a.115.115 0 01-.024-.005l-.013-.005zm-9.6-2.828v-4.47l-1.816.536a1.772 1.772 0 000 3.399l1.817.535z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});