define("ember-svg-jar/inlined/file_ticks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.22 4.8H7.98C6.886 4.8 6 5.673 6 6.75v11.7c0 1.077.886 1.95 1.98 1.95h9.24c1.094 0 1.98-.873 1.98-1.95V6.75c0-1.077-.886-1.95-1.98-1.95zm.105 14.4h-9.45a.667.667 0 01-.675-.66V6.66c0-.365.302-.66.675-.66h9.45c.373 0 .675.295.675.66v11.88c0 .365-.302.66-.675.66zm-6.686-9.82l-.794-.91a.533.533 0 00-.817 0 .6.6 0 000 .79l1.178 1.35a.53.53 0 00.409.19.514.514 0 00.341-.124l2.075-1.994c.218-.248.218-.586.021-.811a.531.531 0 00-.738-.076L10.64 9.38zm-.794 2.69l.794.91 1.675-1.585a.531.531 0 01.738.076c.197.226.197.563-.021.811l-2.075 1.994a.514.514 0 01-.341.124.524.524 0 01-.41-.19l-1.177-1.35a.6.6 0 010-.79.533.533 0 01.817 0zm.794 4.51l-.794-.91a.533.533 0 00-.817 0 .6.6 0 000 .79l1.178 1.35a.53.53 0 00.409.19.514.514 0 00.341-.124l2.075-1.994c.218-.248.218-.585.021-.811a.531.531 0 00-.738-.075L10.64 16.58zM14.9 8.4a.5.5 0 00-.5.5v.2a.5.5 0 00.5.5h1.4a.5.5 0 00.5-.5v-.2a.5.5 0 00-.5-.5h-1.4zm-.5 4.1a.5.5 0 01.5-.5h1.4a.5.5 0 01.5.5v.2a.5.5 0 01-.5.5h-1.4a.5.5 0 01-.5-.5v-.2zm.5 3.1a.5.5 0 00-.5.5v.2a.5.5 0 00.5.5h1.4a.5.5 0 00.5-.5v-.2a.5.5 0 00-.5-.5h-1.4z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});