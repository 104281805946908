define("ember-svg-jar/inlined/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 6H8c-1.264 0-2.25.986-2.25 2.25v6.5l.005.156A2.215 2.215 0 007.69 16.98l.06.005v1.765l.007.105a.75.75 0 001.179.505L12.239 17H16c1.264 0 2.25-.986 2.25-2.25v-6.5C18.25 6.986 17.264 6 16 6zM8 7.5h8c.436 0 .75.314.75.75v6.5c0 .436-.314.75-.75.75h-4l-.116.01a.75.75 0 00-.32.13L9.25 17.292V16.25a.75.75 0 00-.75-.75H8a.724.724 0 01-.75-.75v-6.5c0-.436.314-.75.75-.75z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});