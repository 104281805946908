define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 3.75a2.75 2.75 0 012.75 2.75v.25h2.75a.75.75 0 01.102 1.493l-.102.007h-.75v9.25a2.75 2.75 0 01-2.582 2.745L15 20.25H9a2.75 2.75 0 01-2.75-2.75V8.25H5.5a.75.75 0 01-.102-1.493L5.5 6.75h2.75V6.5a2.75 2.75 0 012.582-2.745L11 3.75h2zm3.25 4.5h-8.5v9.25c0 .647.492 1.18 1.122 1.244L9 18.75h6c.69 0 1.25-.56 1.25-1.25V8.25zm-5.852 1.952a.75.75 0 01.743.648l.007.102v5.174a.75.75 0 01-1.493.101l-.007-.101v-5.174a.75.75 0 01.75-.75zm3.194 0a.75.75 0 01.744.648l.006.102v5.174a.75.75 0 01-1.493.101l-.007-.101v-5.174a.75.75 0 01.75-.75zM13 5.25h-2c-.69 0-1.25.56-1.25 1.25v.25h4.5V6.5a1.25 1.25 0 00-1.122-1.244L13 5.25z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});