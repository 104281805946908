define("ember-svg-jar/inlined/box_download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.859 10.435a.59.59 0 01.428-.171.588.588 0 01.603.596c0 .167-.059.31-.175.425l-3.287 3.235a.588.588 0 01-.428.173.588.588 0 01-.428-.173l-3.287-3.236a.575.575 0 01-.176-.424.59.59 0 01.604-.596.59.59 0 01.428.171l2.255 2.225V4.256c0-.163.06-.306.178-.422A.589.589 0 0112 3.66a.59.59 0 01.427.174.576.576 0 01.177.422v8.404l2.255-2.225z\" fill=\"currentColor\"/><path d=\"M8.609 7.365L6 10.03l-.667.667v5.333c0 .334.267.667.667.667h12c.4 0 .667-.267.667-.667v-5.333L18 10.031l-2.609-2.666h-1.384V6.03H16l4 4v6c0 1.134-.867 2-2 2H6c-1.067 0-2-.866-2-2v-6l4-4h2.002v1.334H8.61z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});