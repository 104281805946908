define("ember-tracked-nested/-private/nested", ["exports", "ember-tracked-nested/-private/deep-equal", "ember-tracked-nested/-private/deep-clone", "@ember/object", "@ember/utils"], function (_exports, _deepEqual, _deepClone, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nested = nested;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  // these methods modifies array in-place
  const ARRAY_MODIFIER_GETTER_METHODS = new Set(['fill', 'pop', 'push', 'reverse', 'shift', 'sort', 'splice', 'unshift']);
  const handler = function (root, paths) {
    return {
      get(target, key) {
        // this is how we know something is already proxified
        if (key === '__isObservedProxy') {
          return true;
        }
        const value = target[key];

        // return if property not found
        if (value === undefined) {
          return;
        }
        // if any in place modification took place in arrays
        // return a wrapping function that will fire the method and then update the root
        if (Array.isArray(target) && ARRAY_MODIFIER_GETTER_METHODS.has(key)) {
          return function () {
            value.apply(target, arguments);
            root.updateArray(paths, key, arguments);
          };
        }

        // do not recreate a new proxy is already proxy
        if (['array', 'object', 'instance'].includes((0, _utils.typeOf)(value)) && !value.__isObservedProxy) {
          target[key] = new Proxy(value, handler(root, [...paths, key]));
        }
        return target[key];
      },
      set(target, key, value) {
        // if key never existed
        // or if replacement is not the deep equaling original
        // else do nothing
        if (!(key in target) || !(0, _deepEqual.default)(target[key], value)) {
          // target[key] = value;
          // trigger update to root
          // we pass root around to each proxy so that it can be notified of changes
          root.update([...paths, key], value);
        }
        return true;
      }
    };
  };
  class Nested {
    constructor(obj, context, member) {
      _defineProperty(this, "raw", void 0);
      _defineProperty(this, "context", void 0);
      _defineProperty(this, "member", void 0);
      _defineProperty(this, "data", void 0);
      this.raw = (0, _deepClone.default)(obj ?? {});
      // @ts-ignore
      this.data = new Proxy((0, _deepClone.default)(this.raw), handler(this, []));
      this.context = context;
      this.member = member;
    }
    update(paths, value) {
      // @ts-ignore
      (0, _object.set)(this.raw, [...paths].join('.'), value);
      this.triggerTracked();
    }
    updateArray(paths, method, args) {
      // @ts-ignore
      const arrayObj = paths.length > 0 ? (0, _object.get)(this.raw, paths.join('.')) : this.raw;
      // @ts-ignore
      arrayObj[method].apply(arrayObj, args);
      this.triggerTracked();
    }
    triggerTracked() {
      this.data = new Proxy((0, _deepClone.default)(this.raw), handler(this, []));
      if (this.context) {
        (0, _object.set)(this.context, this.member, this.data);
      }
    }
  }
  function nested() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let context = arguments.length > 1 ? arguments[1] : undefined;
    let member = arguments.length > 2 ? arguments[2] : undefined;
    // clone object
    return new Nested(data, context, member);
  }
});