define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.707 8.293a1 1 0 00-1.312-.09l-.102.09-4.717 4.713-1.869-1.867-.138-.116a1 1 0 00-1.366 1.428l.09.102L9.87 15.13l.138.116c.351.243.823.236 1.167-.021l.11-.095 5.422-5.423.116-.138a1 1 0 00-.116-1.276z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});