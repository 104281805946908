define("ember-svg-jar/inlined/chevron_down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 14.377l-4.327-4.182a.703.703 0 00-.972 0 .652.652 0 000 .943l4.813 4.667c.268.26.704.26.972 0l4.813-4.667a.655.655 0 000-.943.703.703 0 00-.973 0L12 14.376z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});