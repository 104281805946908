define("ember-svg-jar/inlined/compare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 2.75a.75.75 0 01.743.648l.007.102v.75h2a2.75 2.75 0 012.745 2.582L18.5 7v10a2.75 2.75 0 01-2.582 2.745l-.168.005h-2v.75a.75.75 0 01-1.493.102l-.007-.102v-.75h-3.5a2.75 2.75 0 01-2.745-2.582L6 17V7a2.75 2.75 0 012.582-2.745l.168-.005h3.5V3.5a.75.75 0 01.75-.75zm2.75 3h-2v12.5h2a1.25 1.25 0 001.244-1.122L17 17V7a1.25 1.25 0 00-1.122-1.244l-.128-.006zm-3.5 8.5H9.167l-.102.007a.75.75 0 000 1.486l.102.007h3.083v-1.5zm0-3H9.167l-.102.007a.75.75 0 000 1.486l.102.007h3.083v-1.5zm-1-3H9.167l-.102.007a.75.75 0 000 1.486l.102.007h2.083l.102-.007a.75.75 0 000-1.486l-.102-.007z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});