define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.576 5c3.08 0 5.577 2.556 5.577 5.71a5.78 5.78 0 01-.924 3.147.65.65 0 01.158.115l.012.011 3.403 3.484a.7.7 0 010 .979l-.336.343a.7.7 0 01-.99.012l-.012-.012-3.402-3.483-.07-.084a5.47 5.47 0 01-3.416 1.196C7.496 16.418 5 13.862 5 10.71 5 7.556 7.497 5 10.576 5zm0 1.903c-2.053 0-3.717 1.704-3.717 3.806 0 2.102 1.664 3.806 3.717 3.806 2.054 0 3.718-1.704 3.718-3.806 0-2.102-1.664-3.806-3.718-3.806z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});