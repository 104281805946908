define("ember-svg-jar/inlined/file_tick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.09 9.319V5.183H6.774a.59.59 0 00-.591.59v11.818c0 .326.264.59.59.59h2.819l1.09 1.182H6.774A1.773 1.773 0 015 17.591V5.774C5 4.794 5.794 4 6.773 4h5.879a.578.578 0 01.448.173L17.826 8.9a.58.58 0 01.173.459V12h-1.182V9.91h-4.135a.59.59 0 01-.591-.591zm1.183-.59v-2.71l2.709 2.71h-2.71z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.163 14c.231 0 .428.078.592.235A.755.755 0 0118 14.8c0 .221-.083.41-.248.57l-3.55 3.397a.82.82 0 01-.588.232.827.827 0 01-.594-.232l-1.778-1.695a.758.758 0 01-.242-.57c0-.221.082-.41.245-.566a.825.825 0 01.592-.235c.23 0 .429.08.594.238l1.183 1.133 2.955-2.835a.83.83 0 01.594-.238z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});