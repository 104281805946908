define("ember-svg-jar/inlined/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.6 17.313l-7.005-12.34a1.92 1.92 0 00-3.34 0L3.25 17.312a1.922 1.922 0 001.67 2.87h14.01a1.922 1.922 0 001.67-2.87zm-7.717.602a1.212 1.212 0 01-.828.315c-.318 0-.6-.105-.837-.31-.242-.211-.366-.507-.366-.879 0-.326.116-.608.346-.837.229-.228.512-.345.843-.345.33 0 .616.116.85.345.234.229.354.51.354.837 0 .367-.122.66-.362.874zm.311-7.946l-.305 3.494c-.034.435-.108.763-.225 1.002a.684.684 0 01-.651.403c-.303 0-.526-.137-.646-.396-.106-.23-.179-.564-.223-1.023l-.228-3.4a25.401 25.401 0 01-.064-1.434c0-.423.115-.761.342-1.004.23-.244.534-.37.904-.37.456 0 .768.167.928.493.146.301.218.717.218 1.271 0 .315-.016.639-.05.964z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});