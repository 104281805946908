define("ember-svg-jar/inlined/home_solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.279 5.054a889.417 889.417 0 005.933 4.891c.192.15.288.335.288.555a.7.7 0 01-.216.511.71.71 0 01-.522.217h-1.513v6.044a.7.7 0 01-.216.511.71.71 0 01-.522.217H14.1a.1.1 0 01-.1-.1V15a1 1 0 00-1-1h-2a1 1 0 00-1 1v2.9a.1.1 0 01-.1.1H7.507a.74.74 0 01-.531-.217.687.687 0 01-.225-.511v-6.044H5.256a.74.74 0 01-.53-.217.687.687 0 01-.226-.511c0-.196.09-.381.27-.555l5.962-4.896a2 2 0 012.547.005z\" fill=\"currentColor\" stroke=\"currentColor\" stroke-width=\"1.5\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});