define("ember-svg-jar/inlined/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.13 8.64l.063-.075 4.348-4.373a.645.645 0 01.845-.064l.073.064 4.348 4.373a.67.67 0 01.004.937.645.645 0 01-.848.069l-.074-.064-3.237-3.255v8.085A.657.657 0 018 15a.656.656 0 01-.646-.573l-.006-.09V6.251L4.11 9.507a.645.645 0 01-.85.06l-.072-.065a.67.67 0 01-.06-.863zm17.74 6.72l-.063.075-4.348 4.373a.645.645 0 01-.845.064l-.073-.064-4.348-4.373a.67.67 0 01-.004-.937.645.645 0 01.848-.069l.074.064 3.237 3.255V9.663c0-.366.292-.663.652-.663.33 0 .603.25.646.573l.006.09v8.086l3.237-3.256a.645.645 0 01.85-.06l.072.065a.67.67 0 01.06.863z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});