define("ember-svg-jar/inlined/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.617 7.752A.618.618 0 0012 7.25a.613.613 0 00-.625.6v4.88l-1.564-1.46-.086-.067a.645.645 0 00-.786.067.584.584 0 000 .86l2.625 2.45.086.067c.24.157.57.134.786-.067l2.625-2.45.066-.073a.583.583 0 00-.066-.787.644.644 0 00-.872 0l-1.564 1.46V7.85l-.008-.098z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19 11.5a7 7 0 11-14 0 7 7 0 0114 0zm-1.3 0a5.7 5.7 0 11-11.4 0 5.7 5.7 0 0111.4 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});