define("ember-svg-jar/inlined/information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.25 11.75a.75.75 0 011.5 0v3.5a.75.75 0 01-1.5 0v-3.5zM12 8a.75.75 0 100 1.5.75.75 0 000-1.5z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 4a8 8 0 110 16 8 8 0 010-16zm0 1.333a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.334z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});