define("ember-svg-jar/inlined/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 6.25c3.577 0 7.75 3.706 7.75 5.75s-4.173 5.75-7.75 5.75S4.25 14.044 4.25 12 8.423 6.25 12 6.25zm0 1.5c-1.53 0-3.093.73-4.452 1.936C6.526 10.594 5.75 11.723 5.75 12c0 .277.776 1.406 1.798 2.314C8.907 15.521 10.47 16.25 12 16.25c1.53 0 3.093-.73 4.452-1.936 1.022-.908 1.798-2.037 1.798-2.314 0-.277-.776-1.406-1.798-2.314C15.093 8.479 13.53 7.75 12 7.75zM12 10a2 2 0 110 4 2 2 0 010-4z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});