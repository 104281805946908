define("ember-svg-jar/inlined/structure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.806 4c.936 0 1.694.777 1.694 1.736v1.985c0 .959-.758 1.736-1.694 1.736H7.451v2.33l.986.001c.11-.792.742-1.411 1.527-1.481l.149-.007h6.774c.886 0 1.613.698 1.687 1.587l.007.15v.992c0 .908-.68 1.654-1.548 1.73l-.146.006h-6.774c-.853 0-1.559-.647-1.676-1.488H7.45v3.498c0 .118.08.216.187.242l.056.006h.743c.117-.841.823-1.488 1.676-1.488h6.774c.935 0 1.694.777 1.694 1.736v.993c0 .959-.759 1.736-1.694 1.736h-6.774c-.853 0-1.559-.647-1.676-1.488h-.743c-.889 0-1.618-.702-1.688-1.594L6 16.775V9.446c-.845-.099-1.5-.833-1.5-1.725V5.736C4.5 4.777 5.258 4 6.194 4h11.613zm-.919 13.023h-6.774a.245.245 0 00-.242.248v.993c0 .137.108.248.242.248h6.774a.245.245 0 00.242-.248v-.993a.245.245 0 00-.242-.248zm0-5.235h-6.774a.243.243 0 00-.236.192l-.006.056v.993c0 .117.08.216.186.241l.056.007h6.774c.115 0 .21-.082.236-.191l.006-.057v-.993a.245.245 0 00-.242-.248zm.92-6.3H6.194a.245.245 0 00-.242.248v1.985c0 .137.108.248.242.248h11.613a.245.245 0 00.241-.248V5.736a.245.245 0 00-.241-.248z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});