define("ember-svg-jar/inlined/pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.483 6.18a.63.63 0 00-.89.008l-6.087 6.087a.641.641 0 00-.14.21L6.047 18.14a.624.624 0 00.133.68c.176.178.444.23.68.133l5.654-2.317a.654.654 0 00.21-.141l6.087-6.087a.63.63 0 00.007-.89L15.483 6.18zM9.92 16.158L8.774 15.01l.904-2.198 5.547-5.548L16.29 8.33l-4.824 4.821a.42.42 0 00-.006.593.42.42 0 00.593-.007l4.814-4.814.8.782-5.548 5.547-2.198.905z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});