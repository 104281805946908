define("ember-svg-jar/inlined/bookmark_solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.32 5H7.68C6.751 5 6 5.735 6 6.64v10.72c0 .361.122.713.348 1l.076.09c.58.638 1.575.738 2.279.21L12 16.183l3.297 2.477c.294.22.654.34 1.024.34.927 0 1.679-.735 1.679-1.64V6.64C18 5.736 17.248 5 16.32 5z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});