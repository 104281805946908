define("ember-svg-jar/inlined/box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 6H8l-4 4v6c0 1.133.933 2 2 2h12c1.133 0 2-.867 2-2v-6l-4-4zM8.609 7.333h6.782L18 10h-3.391c0 1.333-1.174 2.667-2.609 2.667S9.391 11.333 9.391 10H6l2.609-2.667zM18.666 16c0 .4-.266.667-.666.667H6A.668.668 0 015.333 16v-4.667H8.2C8.8 12.867 10.333 14 12 14c1.666 0 3.2-1.133 3.733-2.667h2.933V16z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});