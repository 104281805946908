define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.256 4.47a2.75 2.75 0 013.502.007c1.727 1.433 4.73 3.92 5.921 4.882.348.274.571.665.571 1.141 0 .403-.157.762-.435 1.041a1.46 1.46 0 01-1.053.437h-.763v5.294c0 .403-.157.762-.435 1.041a1.46 1.46 0 01-1.053.437H7.507a1.49 1.49 0 01-1.051-.426A1.436 1.436 0 016 17.272v-5.294h-.745a1.49 1.49 0 01-1.051-.426A1.436 1.436 0 013.75 10.5c0-.452.215-.821.5-1.095l.021-.02 5.985-4.916zM12.8 5.63a1.25 1.25 0 00-1.592-.003l-5.905 4.85h2.198v6.77l.003.002h8.995v-6.772h2.179A922.867 922.867 0 0112.8 5.631z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});