define("ember-svg-jar/inlined/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 4.25a7.75 7.75 0 11-5.48 13.23.75.75 0 011.06-1.06 6.25 6.25 0 10-1.749-5.432l.171-.001 1.288-1.25a.744.744 0 01.969-.067l.083.072c.263.266.285.68.068.97l-.072.083-2.555 2.504a.744.744 0 01-.963.07l-.083-.07-2.56-2.511a.744.744 0 01.961-1.129l.084.072 1.118 1.084A7.752 7.752 0 0113 4.25zm0 3.326a.75.75 0 01.743.648l.007.102v3.364l2.136 2.135a.75.75 0 01.072.977l-.072.084a.75.75 0 01-.977.072l-.084-.072-2.355-2.356a.75.75 0 01-.21-.412L12.25 12V8.326a.75.75 0 01.75-.75z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});