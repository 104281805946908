define("ember-svg-jar/inlined/redline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.477 8.123h1.934c1.793 0 2.778.52 2.778 1.962 0 1.426-.985 2.13-2.778 2.13H9.477V8.123zM16 18l-2.936-4.796c1.565-.385 2.584-1.408 2.584-3.119C15.648 7.805 13.96 7 11.622 7H8v11h1.477v-4.645h2.075L14.33 18H16z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});