define("ember-svg-jar/inlined/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 5.75a.75.75 0 01.743.648l.007.102v4.75h4.75a.75.75 0 01.102 1.493l-.102.007h-4.75v4.75a.75.75 0 01-1.493.102l-.007-.102v-4.75H6.5a.75.75 0 01-.102-1.493l.102-.007h4.75V6.5a.75.75 0 01.75-.75z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});