define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 5.25a.75.75 0 01.102 1.493L13 6.75H8a1.25 1.25 0 00-1.244 1.122L6.75 8v8c0 .647.492 1.18 1.122 1.244L8 17.25h8a1.25 1.25 0 001.244-1.122L17.25 16v-5a.75.75 0 011.493-.102l.007.102v5a2.75 2.75 0 01-2.582 2.745L16 18.75H8a2.75 2.75 0 01-2.745-2.582L5.25 16V8a2.75 2.75 0 012.582-2.745L8 5.25h5zm5.682-.543l.61.611a1 1 0 010 1.414l-7.12 7.122a.5.5 0 01-.354.146H10.5a.5.5 0 01-.5-.5v-1.318a.5.5 0 01.146-.354l7.122-7.12a1 1 0 011.414 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});