define("ember-svg-jar/inlined/key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.41 12.236a6.318 6.318 0 11-2.543-2.33l7.815-7.815a1.286 1.286 0 011.818 0l.619.619a1.286 1.286 0 010 1.818l-.753.753 1.994 1.993-3.24 3.258-2.003-2.002-3.706 3.706zm-2.505 3.318a2.872 2.872 0 10-5.744 0 2.872 2.872 0 005.744 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});