define("ember-svg-jar/inlined/requirement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.75 4a2.75 2.75 0 012.745 2.582l.005.168v10a2.75 2.75 0 01-2.582 2.745l-.168.005h-7a2.75 2.75 0 01-2.745-2.582L6 16.75v-10a2.75 2.75 0 012.582-2.745L8.75 4h7zm0 1.5h-7a1.25 1.25 0 00-1.244 1.122L7.5 6.75v10c0 .647.492 1.18 1.122 1.244L8.75 18h7a1.25 1.25 0 001.244-1.122L17 16.75v-10a1.25 1.25 0 00-1.122-1.244L15.75 5.5zm-1.552 4.602a.5.5 0 01.713.682l-.057.07-2.712 2.711-.055.048a.5.5 0 01-.583.01l-.069-.058-1.289-1.288-.044-.052a.5.5 0 01.682-.713l.07.057.934.934 2.358-2.357.052-.044z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});