define("ember-svg-jar/inlined/copy_redline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.75 3a2.75 2.75 0 012.745 2.582l.005.168V6h.25a2.75 2.75 0 012.745 2.582l.005.168v9a2.75 2.75 0 01-2.582 2.745l-.168.005h-6a2.75 2.75 0 01-2.745-2.582L7 17.75v-.25h-.25a2.75 2.75 0 01-2.745-2.582L4 14.75v-9a2.75 2.75 0 012.582-2.745L6.75 3h6zm3 4.5h-6a1.25 1.25 0 00-1.244 1.122L8.5 8.75v9c0 .647.492 1.18 1.122 1.244L9.75 19h6a1.25 1.25 0 001.244-1.122L17 17.75v-9a1.25 1.25 0 00-1.122-1.244L15.75 7.5zm-3-3h-6a1.25 1.25 0 00-1.244 1.122L5.5 5.75v9c0 .647.492 1.18 1.122 1.243L6.75 16H7V8.75a2.75 2.75 0 012.582-2.745L9.75 6H14v-.25a1.25 1.25 0 00-1.122-1.244L12.75 4.5zm.426 6.5c.731 0 1.324.667 1.324 1.49 0 .547-.262 1.025-.654 1.284l.636 1.82c.053.152-.014.325-.15.385-.125.055-.264-.003-.328-.131l-.015-.037-.644-1.844-.083.009-.086.003-1.114-.001v1.72a.29.29 0 01-.28.298.287.287 0 01-.28-.258l-.002-.04v-4.4c0-.141.087-.26.204-.29l.06-.008h1.412zm0 .596H12.03v1.787h1.147c.439 0 .794-.4.794-.893 0-.456-.302-.832-.694-.887l-.1-.007z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});