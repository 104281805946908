define("ember-svg-jar/inlined/bookmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.68 5h8.64c.928 0 1.68.735 1.68 1.64v10.72c0 .905-.752 1.64-1.68 1.64-.37 0-.73-.12-1.023-.34L12 16.183 8.703 18.66a1.708 1.708 0 01-2.279-.21l-.076-.09a1.617 1.617 0 01-.348-1V6.64C6 5.736 6.752 5 7.68 5zm8.64 1.337H7.68a.307.307 0 00-.312.304v10.718a.3.3 0 00.065.185c.104.133.3.159.436.056L12 14.497l4.131 3.103a.316.316 0 00.19.063.308.308 0 00.31-.304V6.641a.308.308 0 00-.31-.304z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});