define("ember-svg-jar/inlined/horn_none", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.26 5.765L5.22 8.428a3.095 3.095 0 000 5.938l2.192.646v1.797A4.191 4.191 0 0011.602 21h1.766a.662.662 0 000-1.324h-1.765a2.868 2.868 0 01-2.868-2.867V15.4l7.877 2.32c.426.142.852.073 1.218-.17.388-.256.611-.651.611-1.125v-2.381a2.869 2.869 0 001.763-2.546 4.857 4.857 0 01-1.342.149 1.54 1.54 0 01-.42.83v-.86a4.817 4.817 0 01-1.324-.347V16.435a.204.204 0 01-.054.033h-.005l-.003.002a.02.02 0 01-.003 0h-.004a.109.109 0 01-.024-.005l-.013-.005-8.277-2.438v-5.25l5.43-1.599a4.955 4.955 0 01.096-1.408zM7.413 9.162v4.47l-1.817-.535a1.772 1.772 0 010-3.4l1.817-.535z\" fill=\"currentColor\"/><path d=\"M19.476 6.302v.771c0 .155-.01.286-.03.394a.759.759 0 01-.094.263.393.393 0 01-.15.145.49.49 0 01-.368.016.359.359 0 01-.134-.089.593.593 0 01-.098-.161 1.108 1.108 0 01-.061-.242 2.495 2.495 0 01-.02-.326v-.77c0-.154.011-.284.031-.39a.77.77 0 01.094-.258.378.378 0 01.148-.143.45.45 0 01.204-.044c.06 0 .116.01.166.028.05.019.095.048.134.09.039.04.072.093.098.158a.948.948 0 01.059.235c.014.092.02.2.02.323z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19 10.4a3.6 3.6 0 100-7.2 3.6 3.6 0 000 7.2zm1.153-3.416v-.59a2.46 2.46 0 00-.054-.544 1.42 1.42 0 00-.154-.41.963.963 0 00-.244-.284.957.957 0 00-.321-.162 1.298 1.298 0 00-.382-.053c-.172 0-.329.028-.47.084a.931.931 0 00-.365.258c-.101.117-.18.268-.236.452a2.316 2.316 0 00-.082.659v.59c0 .207.018.39.053.549.036.16.088.297.155.412a.967.967 0 00.565.45c.119.035.247.052.384.052.172 0 .329-.028.47-.085a.935.935 0 00.362-.26c.104-.118.182-.27.237-.454.055-.186.082-.407.082-.664z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});