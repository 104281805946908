define("ember-svg-jar/inlined/copy_tick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.75 3a2.75 2.75 0 012.745 2.582l.005.168V6h.25a2.75 2.75 0 012.745 2.582l.005.168v9a2.75 2.75 0 01-2.582 2.745l-.168.005h-6a2.75 2.75 0 01-2.745-2.582L8 17.75v-.25h-.25a2.75 2.75 0 01-2.745-2.582L5 14.75v-9a2.75 2.75 0 012.582-2.745L7.75 3h6zm3 4.5h-6a1.25 1.25 0 00-1.244 1.122L9.5 8.75v9c0 .647.492 1.18 1.122 1.244l.128.006h6a1.25 1.25 0 001.244-1.122L18 17.75v-9a1.25 1.25 0 00-1.122-1.244L16.75 7.5zm-3-3h-6a1.25 1.25 0 00-1.244 1.122L6.5 5.75v9c0 .647.492 1.18 1.122 1.243L7.75 16H8V8.75a2.75 2.75 0 012.582-2.745L10.75 6H15v-.25a1.25 1.25 0 00-1.122-1.244L13.75 4.5zm2.604 7.146a.5.5 0 01.057.638l-.057.07-2.712 2.711a.5.5 0 01-.638.058l-.069-.058-1.289-1.288a.5.5 0 01.638-.765l.07.057.934.934 2.358-2.357a.5.5 0 01.708 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});