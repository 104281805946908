define("ember-svg-jar/inlined/hammer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.626 4.677a1.75 1.75 0 012.35-.114l.125.114 2.829 2.828a1.75 1.75 0 01-1.874 2.868 1.31 1.31 0 01-.083.124l5.13 5.132c.643.642.688 1.658.127 2.345l-.1.11-.025.034a1.75 1.75 0 01-2.22.214l-.133-.1-.126-.114-5.13-5.144a2.09 2.09 0 01-.124.085 1.75 1.75 0 01-2.742 1.985l-.125-.114-2.828-2.83A1.75 1.75 0 016.55 9.233c.045-.074.097-.146.156-.214l.092-.1L8.919 6.8c.097-.098.203-.181.315-.25a1.746 1.746 0 01.392-1.872zm2.298 6.893l-.355.355 5.116 5.131c.068.067.161.09.244.07l.059-.024.042-.033.06-.077a.248.248 0 00.02-.187l-.025-.06-.042-.056-5.119-5.119zm-5.833-.883a.25.25 0 00-.394.3l.04.054 2.829 2.828a.25.25 0 00.394-.3l-.04-.053-2.83-2.829zm4.243-2.828a.25.25 0 00-.3-.04l-.054.04L7.859 9.98a.25.25 0 00-.041.3l.04.054 1.415 1.414a.25.25 0 00.3.04l.053-.04 2.122-2.122a.25.25 0 00.04-.3l-.04-.053-1.414-1.414zm.707-2.122a.25.25 0 00-.395.3l.041.054 2.829 2.828a.25.25 0 00.3.041l.053-.04a.25.25 0 00.04-.3l-.04-.054-2.828-2.829z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});