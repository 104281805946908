define("ember-svg-jar/inlined/shoppingbasket_add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.161 7.75c.409.292.9.474 1.432.506l.147.003.004.126.009.113.016.147c.031.21.086.414.162.606L5 9.25a.25.25 0 00-.243.193L4.75 9.5v1a.25.25 0 00.193.243L5 10.75h12.352a2.76 2.76 0 003.398-.912v.662a1.75 1.75 0 01-1.056 1.607l-.637 6.562a1.75 1.75 0 01-1.594 1.575l-.148.006H6.685c-.85 0-1.57-.61-1.72-1.433l-.022-.148-.637-6.562c-.577-.25-.993-.8-1.05-1.454L3.25 10.5v-1a1.75 1.75 0 011.606-1.744L5 7.75h9.161zm4.012 4.5H5.826l.61 6.274a.25.25 0 00.13.196l.057.022.062.008h10.63a.25.25 0 00.235-.165l.014-.06.609-6.275zm-2.682.5l.102.006a.75.75 0 01.657.735l-.006.102-.5 4a.75.75 0 01-1.494-.084l.006-.102.5-4a.75.75 0 01.735-.657zm-3.491 0a.75.75 0 01.743.648l.007.102v4a.75.75 0 01-1.493.102l-.007-.102v-4a.75.75 0 01.75-.75zm-2.77.558l.018.1.496 4a.75.75 0 01-1.469.284l-.02-.1-.495-4a.75.75 0 011.47-.284zM18.5 2c.382 0 .698.282.753.649l.008.112v1.978h1.978a.761.761 0 01.113 1.514l-.113.008h-1.978V8.24l-.008.112a.761.761 0 01-1.506 0l-.008-.112V6.261H15.76a.761.761 0 01-.112-1.514l.112-.008h1.978V2.76l.008-.112A.761.761 0 0118.5 2zm-5.2 2.249c-.193.377-.3.802-.3 1.251l.005.168.007.082H8l-.057.007a.25.25 0 00-.184.175L7.75 6v.5l-.007.102a.75.75 0 01-1.486 0L6.25 6.5V6l.006-.144a1.75 1.75 0 011.593-1.6L8 4.25l5.3-.001z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});