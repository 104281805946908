define("ember-svg-jar/inlined/progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.957 6l4.992.035c.37.002.677.275.73.632l.01.11.035 5a.744.744 0 01-1.479.117l-.008-.11-.028-3.196-5.442 5.442a.75.75 0 01-.979.071l-.084-.073-1.954-1.971-4.47 4.471a.75.75 0 01-.976.073l-.084-.073a.75.75 0 01-.073-.977l.073-.084 5.002-5.002a.75.75 0 01.979-.071l.084.073 1.954 1.969 4.92-4.92-3.209-.027a.744.744 0 01-.732-.638l-.008-.11a.744.744 0 01.638-.733l.11-.008z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});