define("ember-svg-jar/inlined/circle_tick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 4a8 8 0 110 16 8 8 0 010-16zm0 1.333a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.334zm2.716 3.831a.794.794 0 011.05.073c.277.28.308.716.092 1.03l-.092.113-4.339 4.383-.087.077a.794.794 0 01-.933.017l-.111-.094-2.062-2.083-.071-.082a.815.815 0 01.071-1.06.794.794 0 011.02-.094l.112.093 1.495 1.51 3.773-3.81.082-.073z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});