define("ember-svg-jar/inlined/attachement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.91 8.182V16a2.908 2.908 0 11-5.82 0V6.91a1.819 1.819 0 013.637 0v7.636a.73.73 0 01-.727.727.73.73 0 01-.727-.727V8.181a.545.545 0 10-1.091 0v6.364a1.819 1.819 0 003.636 0V6.909A2.908 2.908 0 108 6.91V16c0 2.21 1.79 4 4 4s4-1.79 4-4V8.182a.545.545 0 00-1.09 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});