define("ember-svg-jar/inlined/folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.758 6.5c0-.37.335-.75.842-.75h4.498l2.382 2.29.218.21H18.4c.512 0 .85.384.85.75v7.5c0 .366-.338.75-.85.75H5.6c-.512 0-.85-.384-.85-.75l.008-10z\" stroke=\"currentColor\" stroke-width=\"1.5\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});