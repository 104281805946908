define("ember-svg-jar/inlined/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.75 17a2.75 2.75 0 01-2.582 2.745L16 19.75H9a2.75 2.75 0 01-2.745-2.582L6.25 17V7a2.75 2.75 0 012.582-2.745L9 4.25h4.31l5.44 5.44V17zm-6.5-11.25H9a1.25 1.25 0 00-1.244 1.122L7.75 7v10c0 .647.492 1.18 1.122 1.244L9 18.25h7a1.25 1.25 0 001.244-1.122L17.25 17v-6.25H14a1.75 1.75 0 01-1.726-1.458l-.018-.148L12.25 9V5.75zm3.939 3.5L13.75 6.811V9a.25.25 0 00.193.243L14 9.25h2.189z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});