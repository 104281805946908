define("ember-svg-jar/inlined/view_compact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.75 6c0-.69.56-1.25 1.25-1.25h12.5a1.25 1.25 0 110 2.5H6c-.69 0-1.25-.56-1.25-1.25zm0 5c0-.69.56-1.25 1.25-1.25h12.5a1.25 1.25 0 110 2.5H6c-.69 0-1.25-.56-1.25-1.25zm0 5c0-.69.56-1.25 1.25-1.25h12.5a1.25 1.25 0 110 2.5H6c-.69 0-1.25-.56-1.25-1.25z\" stroke=\"currentColor\" stroke-width=\"1.5\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});