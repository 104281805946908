define("ember-svg-jar/inlined/note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.25 8.75a2.75 2.75 0 00-2.582-2.745L15.5 6h-7a2.75 2.75 0 00-2.745 2.582l-.005.168v7a2.75 2.75 0 002.582 2.745l.168.005h4.31l5.44-5.44V8.75zM11.75 17H8.5a1.25 1.25 0 01-1.244-1.122l-.006-.128v-7c0-.647.492-1.18 1.122-1.244L8.5 7.5h7c.647 0 1.18.492 1.244 1.122l.006.128V12H13.5a1.75 1.75 0 00-1.726 1.458l-.018.149-.006.143V17zm3.939-3.5l-2.439 2.439V13.75a.25.25 0 01.193-.243l.057-.007h2.189z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});