define("ember-svg-jar/inlined/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.28 15c.398 0 .72.336.72.75a.74.74 0 01-.622.743l-.098.007H6.72c-.398 0-.72-.336-.72-.75 0-.38.27-.694.622-.743L6.72 15h10.56zm0-4c.398 0 .72.336.72.75a.74.74 0 01-.622.743l-.098.007H6.72c-.398 0-.72-.336-.72-.75 0-.38.27-.694.622-.743L6.72 11h10.56zm0-4c.398 0 .72.336.72.75a.74.74 0 01-.622.743l-.098.007H6.72c-.398 0-.72-.336-.72-.75 0-.38.27-.693.622-.743L6.72 7h10.56z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});