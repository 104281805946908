define("ember-svg-jar/inlined/chevron_left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.623 12l4.182-4.327a.703.703 0 000-.972.652.652 0 00-.943 0l-4.667 4.813a.703.703 0 000 .972l4.667 4.813a.655.655 0 00.943 0 .703.703 0 000-.973L9.623 12z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});