define("ember-svg-jar/inlined/arrow_right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.45 6.4l.083.072 4.95 5a.75.75 0 01.072.972l-.072.084-4.95 5a.75.75 0 01-1.138-.971l.072-.085 3.684-3.722H6a.75.75 0 01-.102-1.493L6 11.25h9.152l-3.685-3.722a.75.75 0 01-.068-.977l.073-.084a.75.75 0 01.977-.068z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});