/* eslint-disable no-undef */

self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchId: 'ember-string.add-package' },
    {
      handler: 'silence',
      matchId: 'ember-data:deprecate-non-strict-relationships',
    },
    { handler: 'silence', matchId: 'ember-string.from-ember-module' },
    {
      handler: 'silence',
      matchId: 'ember-local-storage.initializers.local-storage-adapter',
    },
    { handler: 'silence', matchId: 'ember-data:no-a-with-array-like' },
    { handler: 'silence', matchId: 'deprecate-ember-error' },
  ],
};
