define("ember-svg-jar/inlined/chevron_right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.377 12l-4.182 4.326a.703.703 0 000 .973c.26.268.683.268.943 0l4.667-4.813a.703.703 0 000-.972l-4.667-4.813a.655.655 0 00-.943 0 .703.703 0 000 .973L14.376 12z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});