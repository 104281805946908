define("ember-svg-jar/inlined/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.511 4.25c.458 0 .898.182 1.222.506l6.51 6.511a1.728 1.728 0 010 2.444l-5.532 5.533a1.728 1.728 0 01-2.444 0l-6.51-6.511a1.728 1.728 0 01-.507-1.222V5.978c0-.954.774-1.728 1.728-1.728h5.533zm0 1.5H5.978a.228.228 0 00-.228.228v5.533c0 .06.024.119.067.161l6.51 6.511c.09.09.234.09.323 0l5.533-5.533a.228.228 0 000-.322l-6.51-6.511a.228.228 0 00-.162-.067zM8.5 7a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0 1a.5.5 0 100 1 .5.5 0 000-1z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});