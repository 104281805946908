define("ember-svg-jar/inlined/norm_revoked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.719 11.082a.961.961 0 010 1.359L13.36 13.8l1.359 1.358a.961.961 0 01-1.36 1.36L12 15.16l-1.36 1.358a.961.961 0 01-1.358-1.359L10.64 13.8l-1.358-1.36a.961.961 0 011.359-1.358L12 12.44l1.358-1.358a.961.961 0 011.36 0zM12.75 7A2.75 2.75 0 0010 4.25H5A2.75 2.75 0 002.25 7v1.75h10.5V7zM10 5.75c.69 0 1.25.56 1.25 1.25v.25h-7.5V7c0-.69.56-1.25 1.25-1.25h5z\" fill=\"currentcolor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.25 7.25H18A3.75 3.75 0 0121.75 11v5A3.75 3.75 0 0118 19.75H6A3.75 3.75 0 012.25 16V7.25zm1.5 1.5V16A2.25 2.25 0 006 18.25h12A2.25 2.25 0 0020.25 16v-5A2.25 2.25 0 0018 8.75H3.75z\" fill=\"currentcolor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});